import { LogTag, Logger, ServiceType } from "@lib/monitoring/logger";
import type { DocumentNode } from "graphql";

const maxRetries: number = 3;
const RETRY_ON_ERROR_CODES = ["502", "503", "504"];

export function updateMutation<T>(execute, fn) {
    const traceID = Math.random().toString(36).substring(7);
    const executeWithRetry = async (variables: T | null, retryCount = 0) => {
        const { error, data } = await execute(variables);
        const mutationName = error
            ? error.graphQLErrors?.[0]?.path?.[0]
            : Object.keys(data || {})[0];
        const logPayload = {
            variables,
            execute,
            mutationName,
            retry: retryCount,
            error,
            service: "retry-" + (process.env.NEXT_PUBLIC_HOSTNAME || ""),
            requestHash: traceID,
        };

        const logMessage = `GraphQL Mutation ${mutationName}`;

        if (
            error &&
            RETRY_ON_ERROR_CODES.some((code) => error.message.includes(code)) &&
            retryCount < maxRetries
        ) {
            Logger.error(ServiceType.COMMERCE_TOOLS, logMessage, {
                tag: LogTag.ERROR,
                ...logPayload,
            });
            return executeWithRetry(variables, retryCount + 1);
        } else {
            if (error) {
                Logger.error(ServiceType.COMMERCE_TOOLS, logMessage, {
                    tag: LogTag.ERROR,
                    ...logPayload,
                });
            } else {
                Logger.info(ServiceType.COMMERCE_TOOLS, logMessage, {
                    tag: LogTag.SUCCESS,
                    ...logPayload,
                });
            }
        }

        // Testing log missing address fields
        if (!error) {
            if (mutationName === "updateMyCart") {
                const allAddressFields = [
                    "streetName",
                    "postalCode",
                    "city",
                    "region",
                    "phone",
                    "firstName",
                    "lastName",
                ];
                const missingFieldsBefore = allAddressFields.filter(
                    (field) => !variables.hasOwnProperty(field)
                );
                const missingFieldsAfter = allAddressFields.filter(
                    (field) => !data.updateMyCart.shippingAddress.hasOwnProperty(field)
                );

                if (missingFieldsBefore.length || missingFieldsAfter.length) {
                    // Send logs to datadog with error and tag as "missing-address-fields"
                    Logger.error(
                        ServiceType.COMMERCE_TOOLS,
                        "Missing shipping address attributes",
                        {
                            ...logPayload,
                            missingFieldsBefore,
                            missingFieldsAfter,
                            tag: LogTag.MISSING_ADDRESS_FIELDS,
                            cartId: data?.updateMyCart?.id,
                            productKeys: data?.updateMyCart?.lineItems?.map(
                                (item) => item.productKey
                            ),
                        }
                    );
                }
            }
            fn(data[mutationName]);
        }
        return { error, data };
    };
    return (variables: T | null) => executeWithRetry(variables);
}

export function updateQuery<T>(execute, fn, selector, document: DocumentNode, filter: any) {
    return async function executor(variables?: T) {
        const injectedFilter = filter;
        if (variables) {
            Object.entries(variables).forEach(([vKey, vValue]) => {
                const token = `$${vKey}`;
                Object.entries(injectedFilter).forEach(([fKey, fValue]) => {
                    if ((fValue as string).indexOf(token) !== -1) {
                        if (Array.isArray(vValue)) injectedFilter[fKey] = vValue;
                        else injectedFilter[fKey] = injectedFilter[fKey].replace(token, vValue);
                    }
                });
            });
        }

        const { error, data } = await execute(document, injectedFilter).toPromise();

        if (!error) {
            fn(selector(data));
        }
        return { error, data };
    };
}
