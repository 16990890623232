import { useRouter } from "next/router";
import { useAuth } from "@lib/auth/useAuth";
import { useStore } from "../../store";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "@lib/helpers/useApi";

import type {
    AddressResponse,
    MeAddressCreateRequest,
    MeAddressResponseResponseModel,
    MeAddressUpdateRequest,
} from "@interfaces/storefront-api";
import type { Customer } from "@graphql/generated/components";
import { QueryKeys } from "@lib/enums/QueryKeys";
import { FetchServiceAxios } from "@lib/helpers/fetchServiceAxios";

const getAddresses = async (basePath: string): Promise<AddressResponse[]> => {
    return FetchServiceAxios.get<AddressResponse[]>(`${basePath}/api/addresses/get-addresses`);
};

export const useGetMyAddresses = () => {
    const { isAuthenticated } = useAuth();
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;

    const { data, isLoading, error } = useApi.useAsync<AddressResponse[]>(
        [QueryKeys.getAddresses],
        () => getAddresses(basePath),
        {
            // We fetch addresses only if user is authenticated
            enabled: isAuthenticated,
        }
    );

    if (data) {
        setData(data as unknown as Customer);
    }

    return {
        data,
        isLoading,
        error,
    };
};

export const useAddAddressToCustomer = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;
    const queryClient = useQueryClient();

    const mutation = useApi.useMutate<MeAddressCreateRequest, MeAddressResponseResponseModel>(
        `${basePath}/api/addresses/add-address`,
        {
            method: "POST",
            onSuccess: (data) => {
                // Invalidate and refetch addresses, so we have the new address in the list
                queryClient.invalidateQueries({ queryKey: [QueryKeys.getAddresses] });
                // Update zustand store
                setData(data as Customer);
            },
        }
    );
    return { addAddressMutation: mutation };
};

export const useUpdateAddressToCustomer = () => {
    const { basePath } = useRouter();
    const queryClient = useQueryClient();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;

    const mutation = useApi.useMutate<MeAddressUpdateRequest, MeAddressResponseResponseModel>(
        `${basePath}/api/addresses/update-address`,
        {
            method: "PUT",
            onSuccess: (data) => {
                // Invalidate and refetch addresses, so we have the new address in the list
                queryClient.invalidateQueries({ queryKey: [QueryKeys.getAddresses] });
                // Update zustand store
                // We need to match types from SFAPI and GraphQL
                setData(data as Customer);
            },
        }
    );
    return { updateAddressMutation: mutation };
};

export const useRemoveAddressToCustomer = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;
    const queryClient = useQueryClient();
    // Ask BE why Swagger is not generating request/response types for delete-address
    const mutation = useApi.useMutate<any, any>(`${basePath}/api/addresses/delete-address`, {
        method: "DELETE",
        onSuccess: (data) => {
            // Invalidate and refetch addresses, so we have the new address in the list
            queryClient.invalidateQueries({ queryKey: [QueryKeys.getAddresses] });
            // Update zustand store
            setData(data as Customer);
        },
    });
    return { deleteAddressMutation: mutation };
};
