import type { Customer } from "@graphql/generated/components";
import { useRouter } from "next/router";
import { useStore } from "../../store";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "@lib/enums/QueryKeys";
import { useAuth } from "@lib/auth/useAuth";
import { useApi } from "@lib/helpers/useApi";
import type {
    MeCustomerRequest,
    MeCustomerResponseResponseModel,
} from "@interfaces/storefront-api";
import { FetchServiceAxios } from "@lib/helpers/fetchServiceAxios";

export const useGetMyProfile = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyProfile) as (customer: Customer) => void;
    const { isAuthenticated } = useAuth();

    const getUser = async () => {
        return await FetchServiceAxios.get<Customer>(`${basePath}/api/user/get-user`);
    };

    const { data, isLoading, error } = useApi.useAsync(
        [QueryKeys.getProfile],
        () => getUser(),
        // We fetch profile only if user is authenticated
        {
            enabled: isAuthenticated,
        }
    );

    if (data) {
        setData(data as Customer);
    }

    return {
        data,
        isLoading,
        error,
    };
};

export const useUpdateProfile = () => {
    const { basePath } = useRouter();
    const queryClient = useQueryClient();
    const setData = useStore((state) => state.setMyProfile) as (customer: Customer) => void;

    const mutation = useApi.useMutate<MeCustomerRequest, MeCustomerResponseResponseModel>(
        `${basePath}/api/user/update-user`,
        {
            method: "PUT",
            onSuccess: (data) => {
                // Invalidate and refetch profile, so we have the new version updated
                queryClient.invalidateQueries({ queryKey: [QueryKeys.getProfile] });
                // Update zustand store, we need to match types from SFAPI and GraphQL
                setData(data as unknown as Customer);
            },
        }
    );
    return { updateMyProfileMutation: mutation };
};
