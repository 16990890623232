import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { useErrorToast } from "@lib/utils/store-utility/storeUtility";
import { useSession } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "store";
import { useGetMyActiveCart, useGetMyActiveData } from "./cartHooks";

export interface UseFreshDataOptions {
    blocking?: boolean;
    fetchCart?: boolean;
}

export const useFreshData = ({
    blocking = false,
    fetchCart = false,
}: UseFreshDataOptions): { ready: boolean } => {
    const hasFreshData = useStore((state) => state.data.hasFreshData);
    const setHasFreshData = useStore((state) => state.setHasFreshData);
    const toast = useErrorToast();
    const { get: getMicrocopy } = useMicrocopy();
    const { data: session, status } = useSession();

    const [ready, setReady] = useState<boolean>(!blocking);
    const executeGetMyActiveData = useGetMyActiveData();
    const executeGetMyActiveCart = useGetMyActiveCart();

    const fetchActiveData = useCallback(async () => {
        if (!session) {
            return;
        }

        const { error } = fetchCart
            ? await executeGetMyActiveCart()
            : await executeGetMyActiveData();

        if (error) {
            toast({
                title: getMicrocopy(CommonCMS.shared, CommonCMS.error.general.title),
                description: getMicrocopy(CommonCMS.shared, CommonCMS.error.general.description),
            });
        }
    }, [session, fetchCart, executeGetMyActiveCart, executeGetMyActiveData, toast, getMicrocopy]);

    useEffect(() => {
        if (!ready || !hasFreshData) {
            setHasFreshData(false);
            if (status === "loading") {
                return;
            }

            fetchActiveData();

            if (blocking && !ready) {
                setReady(true);
            }
            setHasFreshData(true);
        }
    }, [ready, blocking, status, hasFreshData, fetchActiveData]);

    return { ready };
};

export default useFreshData;
